/** @format */

import React from 'react';
import MobileHeader from 'components/Layout/Header/MobileHeader';
import RegularHeader from 'components/Layout/Header/RegularHeader';
import { LeadsGeneratorModal } from 'components/LeadsGenerator';

export default function Header(props) {
  const { t, isMobileView, showContactSellerButton = false } = props;

  const [show, setShow] = React.useState(false);
  const [displayContactSeller, setDisplayContactSeller] = React.useState(false);

  const getButtonColor = (key) => {
    return key === 0 ? 'white' : 'pink';
  };

  const homepageUrl = t(`lang_route:registration_homepage`);

  const buttonText = t('lang_header:buttons');

  return (
    <>
      <RegularHeader
        t={t}
        homepageUrl={homepageUrl}
        buttonText={buttonText}
        getButtonColor={getButtonColor}
        showContactSellerButton={showContactSellerButton}
        setDisplayContactSeller={setDisplayContactSeller}
      />

      {isMobileView && (
        <MobileHeader
          t={t}
          buttonText={buttonText}
          show={show}
          setShow={setShow}
          getButtonColor={getButtonColor}
          setDisplayContactSeller={setDisplayContactSeller}
          homepageUrl={homepageUrl}
        />
      )}

      <LeadsGeneratorModal
        open={displayContactSeller}
        handler={setDisplayContactSeller}
        metadata={{
          source: null,
          brokerId: undefined
        }}
        {...props}
      />
    </>
  );
}
