/** @format */

import React, { useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import config from 'config';
import './styles.scss';
import { NAV_KEYS } from '../common';

export default function MobileHeader(props) {
  const router = useHistory();

  const {
    show,
    setShow,
    buttonText,
    getButtonColor,
    setDisplayContactSeller,
    t,
    homepageUrl = '/'
  } = props;
  const { brokerLoginUrl } = config.app;
  const mobileHeaderOverlayRef = useRef(null);

  useEffect(() => {
    const clickEvent = (event) => {
      if (
        mobileHeaderOverlayRef.current &&
        mobileHeaderOverlayRef.current.contains(event.target)
      ) {
        if (event.target.tagName === 'BUTTON') {
          setShow(false);
        }
      }
    };

    document.addEventListener('click', clickEvent);

    return () => {
      document.removeEventListener('click', clickEvent);
    };
  }, []);

  useEffect(() => {
    if (show) {
      document
        .getElementById('main-container')
        .classList.add('container-hide-padding');
    } else {
      document
        .getElementById('main-container')
        .classList.remove('container-hide-padding');
    }
  }, [show]);

  const Logo = () => (
    <Link to={homepageUrl} className='logo'>
      <div className='logo-fria-maklare-dark' />
    </Link>
  );

  const NavLinksBar = () => {
    const NavElement = ({ activeClass, onClick, label }) => (
      <span className={activeClass} onClick={onClick}>
        {label}
      </span>
    );

    const NavLink = ({ label, to = null, navKey }) => {
      if ([NAV_KEYS.SELL, NAV_KEYS.BUY, NAV_KEYS.APPRAISE].includes(navKey)) {
        // show leads popup

        return (
          <NavElement
            label={label}
            onClick={() => {
              setDisplayContactSeller(true);
            }}
          />
        );
      } else {
        // redirect
        // const activeClass = location?.pathname === `/${to}` ? 'active' : '';

        const redirectionHandler = () => {
          if (to) {
            router.push(to);
          }
        };

        return (
          <NavElement
            activeClass={false}
            label={label}
            onClick={() => redirectionHandler()}
          />
        );
      }
    };

    return (
      <div className='header-nav-links'>
        <NavLink
          label={t('lang_header:sell:button:text')}
          navKey={NAV_KEYS.SELL}
        />
        <NavLink
          label={t('lang_header:appraise:button:text')}
          navKey={NAV_KEYS.APPRAISE}
        />
        <NavLink
          label={t('lang_header:buy:button:text')}
          navKey={NAV_KEYS.BUY}
        />
        <NavLink
          label={t('lang_header:abroad:button:text')}
          to='/abroad'
          navKey={NAV_KEYS.ABROAD}
        />

        <NavLink
          label={t('lang_header:invest:button:text')}
          to={t(`lang_route:registration_invest_page`)}
          navKey={NAV_KEYS.INVEST}
        />
        <NavLink
          label={t('lang_header:monitor:button:text')}
          to={t(`lang_route:registration_monitor_page`)}
          navKey={NAV_KEYS.MONITOR}
        />
        <NavLink
          label={t('lang_header:renovate:button:text')}
          to={t(`lang_route:registration_renovate_page`)}
          navKey={NAV_KEYS.RENOVATE}
        />
      </div>
    );
  };

  return (
    <>
      <div
        className={`hamburger-menu-floating-button ${
          show
            ? 'hamburger-menu-floating-button-close'
            : 'hamburger-menu-floating-button-open'
        }`}
        onClick={() => setShow(!show)}></div>
      {show && (
        <div ref={mobileHeaderOverlayRef} className='mobile-header-overlay'>
          <Logo />
          <NavLinksBar />

          <div className='mobile-header-button-section'>
            {buttonText.map((item, key) => (
              <div className='mobile-header-button-item' key={key}>
                {key === 0 ? (
                  <a href={brokerLoginUrl}>
                    <Button className={`btn btn-${getButtonColor(key)}`}>
                      {item.text}
                    </Button>
                  </a>
                ) : (
                  <Link to={item.props.url}>
                    <Button className={`btn btn-${getButtonColor(key)}`}>
                      {item.text}
                    </Button>
                  </Link>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
