/** @format */

import moment from 'moment';
import 'moment/locale/sv';
import { displayDistance, displayYesOrNull } from './utilities';

/** @format */

export const getFacts = ({
  objectText,
  estate,
  livingSpace,
  otherSpace,
  documentsArray,
  plotArea,
  getBooleanValues,
  patioSquareMeter,
  getNone,
  energyConsumption,
  getEnergyCompletedStatus,
  primaryEnergyNumber,
  electricityConsumption,
  getAssessmentsTotal
}) => ({
  factsArry: [
    {
      title: `${objectText?.interior?.label}`,
      body: [
        {
          title: objectText?.interiorObj?.interiorDescription?.label,
          value: estate?.houseInterior?.interiorDescription
        },
        {
          title: objectText?.interiorObj?.livingArea?.label,
          value:
            livingSpace &&
            livingSpace !== null &&
            livingSpace !== 0 &&
            livingSpace !== '0'
              ? `${livingSpace} ${objectText?.plot?.areaUnit?.label}`
              : false
        },
        {
          title: objectText?.interiorObj?.numberOfRooms?.label,
          value: estate?.houseInterior?.numberOfRooms
        },
        {
          title: objectText?.interiorObj?.numberOfBedRooms?.label,
          value: estate?.houseInterior?.numberOfbedrooms
        },
        {
          title: objectText?.interiorObj?.maxNumberOfBedrooms?.label,
          value: estate?.houseInterior?.maxNumberOfBedrooms
        },
        {
          title: objectText?.interiorObj?.areaSource?.label,
          value: estate?.baseInformation?.areaSource
        },
        {
          title: objectText?.interiorObj?.kitchenType?.label,
          value: estate?.houseInterior?.kitchenType
        },
        {
          title: objectText?.interiorObj?.otherSpace?.label,
          value:
            otherSpace &&
            otherSpace !== null &&
            otherSpace !== 0 &&
            otherSpace !== '0'
              ? `${otherSpace} ${objectText?.plot?.areaUnit?.label}`
              : false
        },
        {
          title: objectText?.interiorObj?.areaSourceComment?.label,
          value: estate?.baseInformation?.areaSourceComment
        },
        {
          title: objectText?.interiorObj?.listOfRooms?.label,
          value: estate?.houseInterior?.listOfRooms
        }
      ]
    },
    {
      title: `${objectText?.building?.label}`,
      body: [
        {
          title: objectText?.buildingObj?.buildingType?.label,
          value: estate?.building?.buildingType
        },
        {
          title: objectText?.buildingObj?.buildingYear?.label,
          value: estate?.building?.buildingYear
        },
        {
          title: objectText?.buildingObj?.comment?.label,
          value: estate?.building?.commentaryForBuildingYear
        },
        {
          title: objectText?.buildingObj?.externalSheetMetalWork?.label,
          value: estate?.building?.externalSheetMetalWork
        },
        {
          title: objectText?.buildingObj?.facade?.label,
          value: estate?.building?.facade
        },
        {
          title: objectText?.buildingObj?.foundation?.label,
          value: estate?.building?.foundation
        },
        {
          title: objectText?.buildingObj?.foundationWall?.label,
          value: estate?.building?.foundationWall
        },
        {
          title: objectText?.buildingObj?.frame?.label,
          value: estate?.building?.frame
        },
        {
          title: objectText?.buildingObj?.heating?.label,
          value: estate?.building?.heating
        },
        {
          title: objectText?.buildingObj?.roof?.label,
          value: estate?.building?.roof
        },
        {
          title: objectText?.buildingObj?.windows?.label,
          value: estate?.building?.windows
        },
        {
          title: objectText?.buildingObj?.beam?.label,
          value: estate?.building?.beam
        },
        {
          title: objectText?.buildingObj?.other?.label,
          value: estate?.building?.otherAboutTheBuilding
        },
        {
          title: objectText?.buildingObj?.otherBuildings?.label,
          value: estate?.building?.otherBuildings
        }
      ]
    },
    {
      title: `${objectText?.energypc?.label}`,
      body: [
        {
          title: objectText?.energypcObj?.energyClass?.label,
          value: getNone(estate?.energyDeclaration?.energyClass)
        },
        {
          title: objectText?.energypcObj?.energyConsumption?.label,
          value:
            energyConsumption &&
            energyConsumption !== 0 &&
            energyConsumption !== null &&
            energyConsumption !== '0'
              ? `${energyConsumption} ${objectText?.opcost?.kwhPerSqMt?.label}`
              : false
        },
        {
          title: objectText?.energypcObj?.energyDeclarationCompleted?.label,
          value: getEnergyCompletedStatus(
            estate?.energyDeclaration?.energyDeclarationCompleted
          )
        },
        {
          title: objectText?.energypcObj?.energyDeclarationDate?.label,
          value: moment(
            estate?.energyDeclaration?.energyDeclarationDate
          ).format('YYYY-MM-DD')
        },
        {
          title: objectText?.energypcObj?.energyPerPriEngNum?.label,
          value:
            primaryEnergyNumber &&
            primaryEnergyNumber !== 0 &&
            primaryEnergyNumber !== null &&
            primaryEnergyNumber !== '0'
              ? `${primaryEnergyNumber} ${objectText?.opcost?.kwh?.label}`
              : false
        }
      ]
    },
    {
      title: `${objectText?.plotPatioCarParkingArea?.label}`,
      body: [
        {
          title: objectText?.plot?.area?.label,
          value:
            plotArea && plotArea !== null && plotArea !== 0 && plotArea !== '0'
              ? `${plotArea} ${objectText?.plot?.areaUnit?.label}`
              : false
        },
        {
          title: objectText?.plot?.otherPlot?.label,
          value: estate?.plot?.otherPlot
        },
        {
          title: objectText?.plot?.parking?.label,
          value: estate?.plot?.parking
        },
        {
          title: objectText?.plot?.parkingLot?.label,
          value: getBooleanValues(estate?.plot?.parkingLot)
        },
        {
          title: objectText?.plot?.patio?.label,
          value: getBooleanValues(estate?.plot?.patio)
        },
        {
          title: objectText?.plot?.type?.label,
          value: estate?.plot?.type
        },
        {
          title: objectText?.plot?.balcony?.label,
          value: getBooleanValues(estate?.patio?.balcony)
        },
        {
          title: objectText?.plot?.squareMeterType?.label,
          value: estate?.patio?.squareMeterype
        },
        {
          title: objectText?.plot?.summary?.label,
          value: estate?.patio?.summary
        },
        {
          title: objectText?.plot?.type?.label,
          value: estate?.patio?.type
        },
        {
          title: objectText?.plot?.space?.label,
          value:
            patioSquareMeter &&
            patioSquareMeter !== null &&
            patioSquareMeter !== 0 &&
            patioSquareMeter !== '0'
              ? `${patioSquareMeter} ${objectText?.plot?.areaUnit?.label}`
              : false
        },
        {
          title: objectText?.plot?.description?.label,
          value: estate?.groundIncludetToHousingCooperative?.description
        }
      ]
    },
    {
      title: `${objectText?.assess?.label}`,
      body: [
        {
          title: objectText?.assess?.buildingValue?.label,
          value: estate?.assess?.buildingValue
        },
        {
          title: objectText?.assess?.landValue?.label,
          value: estate?.assess?.landValue
        },
        {
          title: objectText?.assess?.leasholdFee?.label,
          value: estate?.assess?.leasholdFee
        },
        {
          title: objectText?.assess?.leasholdUntil?.label,
          value: estate?.assess?.leasholdUntil
        },
        {
          title: objectText?.assess?.preliminaryAssessedValue?.label,
          value: getBooleanValues(estate?.assess?.preliminaryAssessedValue)
        },
        {
          title: objectText?.assess?.taxAssessmentYear?.label,
          value: estate?.assess?.taxAssessmentYear
        },
        {
          title: objectText?.assess?.total?.label,
          value: getAssessmentsTotal(estate?.assessments)
        },
        {
          title: objectText?.assess?.taxFee?.label,
          value: estate?.assess?.taxFee
        },
        {
          title: objectText?.assess?.totalAssessedValue?.label,
          value: estate?.assess?.totalAssessedValue
        },

        {
          title: objectText?.assess?.valueYear?.label,
          value: estate?.assess?.valueYear
        },
        {
          title: objectText?.assessments?.label,
          value: estate?.assessments
        }
      ]
    },
    {
      title: objectText.exterior.label,
      body: [
        {
          title: objectText.exterior.pool.label,
          value: displayYesOrNull(estate?.extra?.exterior?.pool)
        },
        {
          title: objectText.exterior.pool_description.label,
          value: estate?.extra?.exterior?.descriptionOfPool
        },
        {
          title: objectText.exterior.balcony.label,
          value: displayYesOrNull(estate?.extra?.exterior?.balcony)
        },
        {
          title: objectText.exterior.balcony_surface.label,
          value: estate?.extra?.exterior?.balconySurface
        },
        {
          title: objectText.exterior.terrace.label,
          value: displayYesOrNull(estate?.extra?.exterior?.terrace)
        },
        {
          title: objectText.exterior.terrace_surface.label,
          value: estate?.extra?.exterior?.terraceSurface
        },
        {
          title: objectText.exterior.terrace_balcony_desc.label,
          value: estate?.extra?.exterior?.descriptionTerraceAndBalcony
        }
      ]
    },
    {
      title: objectText.distances.label,
      body: [
        {
          title: objectText?.distances?.airport?.label,
          value: displayDistance(estate?.extra?.distance?.airport)
        },
        {
          title: objectText?.distances?.beach?.label,
          value: displayDistance(estate?.extra?.distance?.beach)
        },
        {
          title: objectText?.distances?.centre?.label,
          value: displayDistance(estate?.extra?.distance?.centre)
        },
        {
          title: objectText.distances.golf_course.label,
          value: displayDistance(estate?.extra?.distance?.golfCource)
        },
        {
          title: objectText.distances.hospital.label,
          value: displayDistance(estate?.extra?.distance?.hospital)
        },
        {
          title: objectText.distances.swimming_pool.label,
          value: displayDistance(estate?.extra?.distance?.pool)
        },
        {
          title: objectText.distances.sea.label,
          value: displayDistance(estate?.extra?.distance?.sea)
        },
        {
          title: objectText.distances.shopping_centre.label,
          value: displayDistance(estate?.extra?.distance?.shoppingCentre)
        },
        {
          title: objectText.distances.super_market.label,
          value: displayDistance(estate?.extra?.distance?.supermarket)
        }
      ]
    },

    {
      title: `${objectText?.waterasw?.label}`,
      body: [
        {
          title: objectText?.wateraswObj?.waterAndDrainInfo?.label,
          value: estate?.waterAndDrainInfo
        }
      ]
    },
    {
      title: objectText?.charge?.label,
      body: []
    },
    {
      title: objectText?.floorAndElevator?.label,
      body: [
        {
          title: objectText?.floorAndElevator?.elevator?.label,
          value: displayYesOrNull(estate?.extra?.floorAndElevator?.elevator)
        },
        {
          title: objectText?.floorAndElevator?.descriptionOfElevator?.label,
          value: estate?.extra?.floorAndElevator?.descriptionOfElevator
        },

        {
          title: objectText?.floorAndElevator?.floor?.label,
          value: estate?.extra?.floorAndElevator?.floor
        },
        {
          title: objectText?.floorAndElevator?.floorCommentary?.label,
          value: estate?.extra?.floorAndElevator?.floorCommentary
        },
        {
          title: objectText?.floorAndElevator?.totalNumberOfFloors?.label,
          value: estate?.extra?.floorAndElevator?.totalNumberOfFloors
        }
      ]
    },
    {
      title: `${objectText?.opcost?.label}`,
      body: [
        {
          title: objectText?.opcost?.electricity?.label,
          value: estate?.operation?.electricity
        },
        {
          title: objectText?.opcost?.heating?.label,
          value: estate?.operation?.heating
        },
        {
          title: objectText?.opcost?.insurance?.label,
          value: estate?.operation?.insurance
        },
        {
          title: objectText?.opcost?.personsInTheHousehold?.label,
          value: estate?.operation?.personsInTheHousehold
        },
        {
          title: objectText?.opcost?.roadFee?.label,
          value: estate?.operation?.roadFee
        },
        {
          title: objectText?.opcost?.communityFee?.label,
          value: estate?.operation?.communityFee
        },
        {
          title: objectText?.opcost?.insuranceCompany?.label,
          value: estate?.baseInformation?.insuranceCompany
        },
        {
          title: objectText?.opcost?.insuranceFee?.label,
          value: estate?.operation?.insurance
        },
        {
          title: objectText?.opcost?.insuranceFullValue?.label,
          value: getBooleanValues(estate?.operation?.insurance_fullValue)
        },
        {
          title: objectText?.opcost?.networkCompany?.label,
          value: estate?.electricity?.electricityCompany
        },
        {
          title: objectText?.opcost?.wow?.label,
          value: estate?.operation?.waterAndDrain
        },
        {
          title: objectText?.opcost?.electricityDistributor?.label,
          value: estate?.electricity?.distributor
        },
        {
          title: objectText?.opcost?.electricityConsumption?.label,
          value:
            electricityConsumption &&
            electricityConsumption !== 0 &&
            electricityConsumption !== null &&
            electricityConsumption !== '0'
              ? `${electricityConsumption} ${objectText?.opcost?.kwh?.label}`
              : false
        },
        {
          title: objectText?.opcost?.sanitization?.label,
          value: estate?.operation?.sanitization
        },
        {
          title: objectText?.opcost?.sweeping?.label,
          value: estate?.operation?.sweeping
        },
        {
          title: objectText?.opcost?.other?.label,
          value: estate?.operation?.other
        },
        {
          title: objectText?.opcost?.sum?.label,
          value: estate?.operation?.sum
        },
        {
          title: objectText?.opcost?.commentary?.label,
          value: estate?.operation?.commentary
        },
        {
          title: objectText?.opcost?.insuranceAmount?.label,
          value: estate?.operation?.insurance_amount
        }
      ]
    },
    {
      title: `${objectText?.environment?.label}`,
      body: [
        {
          title: objectText?.environmentObj?.nameOfField?.label,
          value: estate?.baseInformation?.address.area
        },
        {
          title: objectText?.environmentObj?.generalInfoArea?.label,
          value: estate?.surrounding?.generalAboutArea
        },
        {
          title: objectText?.environmentObj?.communication?.label,
          value: estate?.surrounding?.communication
        },
        {
          title: objectText?.environmentObj?.closeService?.label,
          value: estate?.surrounding?.nearService
        },
        {
          title: objectText?.environmentObj?.parking?.label,
          value: estate?.surrounding?.parking
        },
        {
          title: objectText?.environmentObj?.other?.label,
          value: estate?.surrounding?.other
        }
      ]
    },
    // {
    //   title: `${objectText?.enrolmentPlans?.label}`,
    //   body: [
    //     {
    //       title: objectText?.enrolmentPlansObj?.preferentialAndCommunity.label,
    //       value: estate?.enrollments?.enrollmentPreferentialAndCommunity
    //     },
    //     {
    //       title: objectText?.enrolmentPlansObj?.enrollmentPlanRegulations.label,
    //       value: estate?.enrollments?.enrollmentPlanRegulations
    //     }
    //   ]
    // },

    // {
    //   title: `${objectText?.priceUnderDeal?.label}`,
    //   body: [
    //     {
    //       title: objectText?.price?.startPrice?.label,
    //       value: estate?.price?.startingPrice
    //     },
    //     {
    //       title: objectText?.price?.finalPrice?.label,
    //       value: estate?.price?.finalPrice
    //     },
    //     {
    //       title: objectText?.price?.priceText?.label,
    //       value: estate?.price?.priceText
    //     },
    //     {
    //       title: objectText?.price?.otherCurrency?.label,
    //       value: estate?.price?.otherCurrency
    //     },
    //     {
    //       title: objectText?.price?.priceInOtherCurrency?.label,
    //       value: estate?.price?.priceInOtherCurrency
    //     }
    //   ]
    // },
    {
      title: `${objectText?.documents?.label}`,
      body: documentsArray
    }

    // {
    //   title: `${objectText?.ventilation?.label}`,
    //   body: [
    //     {
    //       title: objectText?.ventilationObj?.inspection?.label,
    //       value: estate?.ventilation?.inspection
    //     },
    //     {
    //       title: objectText?.ventilationObj?.type?.label,
    //       value: estate?.ventilation?.type
    //     }
    //   ]
    // }
  ],

  factsArryExtra: [],

  factsArryApartment: [
    {
      title: `${objectText?.participationAndRepairFund?.label}`,
      body: [
        {
          title: objectText?.participationAndRepairFund?.indirectNetDebt?.label,
          value: estate?.participationAndRepairFund?.indirectNetDebt
        },
        {
          title:
            objectText?.participationAndRepairFund?.indirectNetDebtComment
              ?.label,
          value: estate?.participationAndRepairFund?.indirectNetDebtComment
        },

        {
          title:
            objectText?.participationAndRepairFund?.participationInAssociation
              ?.label,
          value: estate?.participationAndRepairFund?.participationInAssociation
        },
        {
          title:
            objectText?.participationAndRepairFund?.participationOffAnnualFee
              ?.label,
          value: estate?.participationAndRepairFund?.participationOffAnnualFee
        },
        {
          title: objectText?.participationAndRepairFund?.repairFund?.label,
          value: estate?.participationAndRepairFund?.repairFund
        },
        {
          title: objectText?.participationAndRepairFund?.shareComment?.label,
          value: estate?.participationAndRepairFund?.shareComment
        },
        {
          title:
            objectText?.participationAndRepairFund?.commentaryOfMonthlyFee
              ?.label,
          value: estate?.baseInformation?.commentaryOfMonthlyFee
        },
        {
          title: objectText?.participationAndRepairFund?.monthlyFee?.label,
          value: estate?.baseInformation?.monthlyFee
        }
      ]
    }
  ]
});
