/** @format */

export const NAV_KEYS = {
  SELL: 'SELL',
  BUY: 'BUY',
  APPRAISE: 'APPRAISE',
  ABROAD: 'ABROAD',
  INVEST: 'INVEST',
  MONITOR: 'MONITOR',
  RENOVATE: 'RENOVATE'
};
