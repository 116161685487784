/** @format */

/** styles  */
import 'bootstrap/dist/css/bootstrap.min.css';
import 'assets/styles';

import React from 'react';
import config from 'config';
import i18n from 'i18next';
import Routes from 'routes';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from 'react-i18next';
import { history } from 'stateContainer/history';
import Container from 'react-bootstrap/Container';
import ErrorBoundary from 'components/ErrorBoundary';
import ChainedBackend from 'i18next-chained-backend';
import { store, persistor } from 'stateContainer/store';
import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';
import LanguageDetector from 'i18next-browser-languagedetector';
import { HelmetProvider } from 'react-helmet-async';

const root = ReactDOM.createRoot(document.getElementById('root'));

i18n
  .use(ChainedBackend) // bind translations originating from local and locize namespaces
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(config.i18nConfig)
  .then(() => {
    return root.render(
      <ErrorBoundary>
        <PersistGate loading={null} persistor={persistor}>
          <HelmetProvider>
            <Provider store={store}>
              <ConnectedRouter history={history} noInitialPop>
                <Container id='main-container' fluid>
                  <Routes />
                </Container>
              </ConnectedRouter>
            </Provider>
          </HelmetProvider>
        </PersistGate>
      </ErrorBoundary>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
