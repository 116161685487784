/** @format */

import React from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Intro from 'views/Registration/HomePage/Intro';
import Partners from 'views/Registration/HomePage/Partners';
import Benefits from 'views/Registration/HomePage/Benefits';
import WhatWeDo from 'views/Registration/HomePage/WhatWeDo';
import FeeCalculator from 'views/Registration/HomePage/FeeCalculator';
import StickyAnimations from 'views/Registration/HomePage/StickyAnimations';

export default function HomePage(props) {
  const { tprop } = props;

  return (
    <>
      <div className='main-container homepage-view'>
        <Header showContactSellerButton={true} {...props} />

        <Container fluid className='content-wrapper'>
          <Intro {...props} tprop={tprop.intro} />

          <Partners {...props} tprop={tprop.partners} />

          <StickyAnimations {...props} tprop={tprop.whatWeOffer} />

          <FeeCalculator {...props} tprop={tprop.feeCalculator} />

          <Benefits {...props} tprop={tprop.benefits} />

          <WhatWeDo {...props} tprop={tprop.whatWeDo} />
        </Container>

        <Footer {...props} />
      </div>
    </>
  );
}
