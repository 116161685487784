/** @format */

export const displayDistance = (value) => {
  const processDistance = () => {
    if (value >= 1000) {
      return `${(value / 1000).toFixed(2)} km`;
    } else {
      return `${value} m`;
    }
  };

  if (value && !isNaN(value)) {
    return processDistance();
  }
  return null;
};

export const displayYesOrNull = (value) => {
  if (value) {
    return 'Yes';
  }

  return null;
};
