/** @format */

export const getTranslation = (props) => {
  const { t: translate } = props;
  const text = translate('lang_registration:register');

  return {
    mainHeading: text.heading,
    firstName: text.form_label_1,
    lastName: text.form_label_2,
    email: text.form_label_3,
    placeholder: text.placeholder,
    moreInfo: props.tprop.buttons[0].label,
    sendButtonText: props.tprop.buttons[1].label,
    formErrors: {
      firstName: translate(
        'lang_registration:register.messages.validation.firstNameRequired'
      ),
      lastName: translate(
        'lang_registration:register.messages.validation.lastNameRequired'
      ),
      email: translate(
        'lang_registration:register.messages.validation.emailRequired'
      ),
      emailInvalid: translate(
        'lang_registration:register.messages.validation.emailInvalid'
      ),
      city: translate(
        'lang_registration:invest:messages:validation:cityRequired'
      )
    },
    sentSuccess: translate(
      'lang_registration:register.messages.apiSuccessMessages.RB_Success'
    )
  };
};
