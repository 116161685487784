/** @format */

import React, { useState } from 'react';
import Header from 'components/Layout/Header';
import Footer from 'components/Layout/Footer';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// import { registerUserApiService } from 'services/apiService';
import AlertBox from 'components/Alert';
import { AiOutlineExclamationCircle } from 'react-icons/ai';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ButtonSyncLoader from 'components/ButtonSyncLoader';
import { Link } from 'react-router-dom';
import { getTranslation } from './translations';
import './styles.scss';
import { regions } from '../../Onboarding/Onboarding/ActiveAreas/regions';

export const ConsultPageModes = {
  INVEST: 'invest',
  MONITOR: 'monitor',
  RENOVATE: 'renovate'
};

const getConsultantParams = (props) => {
  const { t, mode } = props;
  switch (mode) {
    case ConsultPageModes.INVEST:
      return {
        heading: t('lang_registration:invest:heading'),
        firstDescription: t('lang_registration:invest:first_description'),
        extraInputLabel: t('lang_registration:invest.extra_info.label'),
        cityInputLabel: t('lang_registration:invest.city.label')
      };
    case ConsultPageModes.MONITOR:
      return {
        heading: t('lang_registration:monitor:heading'),
        firstDescription: t('lang_registration:monitor:first_description'),
        extraInputLabel: t('lang_registration:invest.extra_info.label'),
        cityInputLabel: t('lang_registration:invest.city.label')
      };
    case ConsultPageModes.RENOVATE:
      return {
        heading: t('lang_registration:renovate:heading'),
        firstDescription: t('lang_registration:renovate:first_description'),
        extraInputLabel: t('lang_registration:invest.extra_info.label'),
        cityInputLabel: t('lang_registration:invest.city.label')
      };

    default:
      return {
        heading: '',
        firstDescription: '',
        extraInputLabel: '',
        cityInputLabel: ''
      };
  }
};

const ImportantFieldMark = () => <span style={{ color: 'red' }}>*</span>;

export default function ConsultantPage(props) {
  // const text = props.t('lang_registration:register');
  const { mode, t } = props;

  const { heading, firstDescription, extraInputLabel, cityInputLabel } =
    getConsultantParams(props);

  const translatedText = getTranslation(props);

  const [dataSubmitPendingState, setDataSubmitPendingState] = useState(false);
  const [dataSubmitSuccessState, setDataSubmitSuccessState] = useState(false);
  const [dataSubmitFailMessageState, setDataSubmitFailMessageState] =
    useState(null);

  const resetDataSubmitStates = () => {
    setDataSubmitSuccessState(false);
    setDataSubmitFailMessageState(null);
  };

  const formikValidationSchema = () =>
    Yup.object().shape({
      firstName: Yup.string()
        .trim()
        .required(translatedText.formErrors.firstName),
      lastName: Yup.string()
        .trim()
        .required(translatedText.formErrors.lastName),
      city: Yup.string().required(translatedText.formErrors.city),
      email: Yup.string()
        .email(translatedText.formErrors.emailInvalid)
        .required(translatedText.formErrors.email)
    });

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      message: ''
    },
    // onSubmit: (values, { resetForm }) => {
    onSubmit: (values) => {
      resetDataSubmitStates();
      if (values.firstName && values.lastName && values.email) {
        setDataSubmitFailMessageState(null);
        setDataSubmitPendingState(true);
        // registerUserApiService(
        //   values.firstName,
        //   values.lastName,
        //   values.email,
        //   abroadBrokers || false,
        //   {
        //     message: values?.message || null
        //   }
        // )
        //   .then(() => {
        //     resetForm();
        //     setDataSubmitSuccessState(true);
        //   })
        //   .catch((error) => {
        //     const errorMessageCode = error.response.data.languageErrorCode
        //       ? error.response.data.languageErrorCode
        //       : 'RB_GeneralError';
        //     setDataSubmitFailMessageState(
        //       props.t(
        //         `lang_registration:register.messages.apiErrorMessages.${errorMessageCode}`
        //       )
        //     );
        //   })
        //   .finally(() => {
        //     setDataSubmitPendingState(false);
        //   });
      } else {
        setDataSubmitPendingState(false);
      }
    },
    validationSchema: formikValidationSchema,
    validateOnChange: false,
    validateOnBlur: false
  });

  const Description = () => (
    <p className='text2r registerpage-description'>{firstDescription}</p>
  );

  const ImageSection = () => {
    switch (mode) {
      case ConsultPageModes.INVEST:
        return (
          <div className='invest-image-section'>
            <span className='next-course'>
              {t('lang_registration:invest:next_course:label')} : 2025 02 25
            </span>
            <img
              height={200}
              width={250}
              src='images/online-training-concept.jpg'
              className='registerpage-image-1 invest-image'
              alt='invest page image'
            />
            <Button
              className={
                'btn btn-pink registerpage-button-spacing signup-course-button'
              }>
              {t('lang_registration:invest:course_signup:button:label')}
            </Button>
          </div>
        );
      case ConsultPageModes.MONITOR:
        return (
          <div className='invest-image-section'>
            <img
              height={200}
              width={250}
              src='images/monitor.jpg'
              className='registerpage-image-1 monitor-image'
              alt='monitor page image'
            />
          </div>
        );
      case ConsultPageModes.RENOVATE:
        return (
          <div className='invest-image-section'>
            <img
              height={200}
              width={250}
              src='images/renovate.jpg'
              className='registerpage-image-1 renovate-image'
              alt='renovate page image'
            />
          </div>
        );

      default:
        return (
          <img
            src='images/image-registration-page.png'
            className='registerpage-image-1'
            alt='abroad registration image'
          />
        );
    }
  };

  return (
    <>
      <div className='main-container'>
        <Header {...props} />
        <div className='registerpage-inner-container'>
          <Container>
            <h3>{heading}</h3>

            <Row>
              <Col md={8}>
                <Description />

                <form onSubmit={formik.handleSubmit}>
                  {dataSubmitSuccessState && (
                    <Row>
                      <Col>
                        <AlertBox
                          type='success'
                          text={translatedText.sentSuccess}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  )}
                  {dataSubmitFailMessageState && (
                    <Row>
                      <Col>
                        <AlertBox
                          type='danger'
                          text={dataSubmitFailMessageState}
                          icon={<AiOutlineExclamationCircle />}
                          iconConfig={{ size: '18px' }}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col md={12} className='registerpage-field-container'>
                      <label htmlFor='region' className='text3m'>
                        {cityInputLabel} <ImportantFieldMark />
                      </label>
                      <select
                        // key={citySelectKey}
                        id='city'
                        name='city'
                        className='registerpage-field-input text2r'
                        defaultValue=''
                        onChange={formik.handleChange}>
                        <option
                          key=''
                          className='text2r'
                          selected={true}
                          value=''>
                          {translatedText.placeholder}
                        </option>
                        {regions.map((value, index) => (
                          <option className='text2r' value={value} key={index}>
                            {value}
                          </option>
                        ))}
                      </select>
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.city}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='firstName' className='text3m'>
                        {translatedText.firstName} <ImportantFieldMark />
                      </label>
                      <input
                        id='firstName'
                        name='firstName'
                        type='text'
                        placeholder={translatedText.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.firstName}
                      </label>
                    </Col>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='lastName' className='text3m'>
                        {translatedText.lastName} <ImportantFieldMark />
                      </label>
                      <input
                        id='lastName'
                        name='lastName'
                        type='text'
                        placeholder={translatedText.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.lastName != undefined &&
                          formik.errors.lastName}
                      </label>
                    </Col>
                  </Row>

                  <Row>
                    <Col className='registerpage-field-container'>
                      <label htmlFor='email' className='text3m'>
                        {translatedText.email} <ImportantFieldMark />
                      </label>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        placeholder={translatedText.placeholder}
                        className='registerpage-field-input'
                        onChange={formik.handleChange}
                        value={formik.values.email}
                      />
                      <label className='registerpage-description registerpage-validation-error-label'>
                        {formik.errors.email != undefined &&
                          formik.errors.email}
                      </label>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} className='registerpage-field-container'>
                      <label htmlFor='message' className='text3m'>
                        {extraInputLabel}
                      </label>
                      <textarea
                        id='message'
                        name='message'
                        type='test'
                        onChange={formik.handleChange}
                        value={formik.values.message}
                        placeholder={translatedText.placeholder}
                        className='contactUs-field-textarea contactUs-description'
                      />
                    </Col>
                  </Row>

                  <div className='registerpage-button-container'>
                    <Link to={props.t(`lang_route:registration_article`)}>
                      <Button
                        className={
                          'btn btn-white registerpage-button-spacing mobile-registerpage-button-width'
                        }>
                        {translatedText.moreInfo}
                      </Button>
                    </Link>
                    <Button
                      className={
                        'btn btn-pink mobile-registerpage-button-width'
                      }
                      type='submit'
                      disabled={dataSubmitPendingState}>
                      {dataSubmitPendingState ? (
                        <ButtonSyncLoader />
                      ) : (
                        translatedText.sendButtonText
                      )}
                    </Button>
                  </div>
                </form>
              </Col>
              <Col
                md={{ span: 3, offset: 1 }}
                xs={0}
                className='mobile-loginpage-image'>
                <ImageSection />
              </Col>
            </Row>
          </Container>
        </div>

        <Footer {...props} />
      </div>
    </>
  );
}
