/** @format */

export const getTranslation = (props) => {
  const { abroadBrokers } = props;
  const text = props.t('lang_registration:register');

  const abroadEnglishVersion = {
    mainHeading: text?.abroad?.mainHeading,
    descriptionFirstLine: text?.abroad?.descriptionFirstLine,
    descriptionSecondLine: text?.abroad?.descriptionSecondLine,
    firstName: text?.abroad?.firstName,
    lastName: text?.abroad?.lastName,
    email: text?.abroad?.email,
    message: text?.abroad?.message,
    placeholder: text?.abroad?.placeholder,
    moreInfo: text?.abroad?.moreInfo,
    sendButtonText: text?.abroad?.sendButtonText,
    formErrors: {
      firstName: 'First Name must be filled',
      lastName: 'Last Name must be filled',
      email: 'Email Address must be filled',
      emailInvalid: 'Email Address is invalid'
    },
    sentSuccess: text?.abroad?.sentSuccess
  };

  const localSwedishVersion = {
    mainHeading: text.heading,
    firstName: text.form_label_1,
    lastName: text.form_label_2,
    email: text.form_label_3,
    placeholder: text.placeholder,
    moreInfo: props.tprop.buttons[0].label,
    sendButtonText: props.tprop.buttons[1].label,
    formErrors: {
      firstName: props.t(
        'lang_registration:register.messages.validation.firstNameRequired'
      ),
      lastName: props.t(
        'lang_registration:register.messages.validation.lastNameRequired'
      ),
      email: props.t(
        'lang_registration:register.messages.validation.emailRequired'
      ),
      emailInvalid: props.t(
        'lang_registration:register.messages.validation.emailInvalid'
      )
    },
    sentSuccess: props.t(
      'lang_registration:register.messages.apiSuccessMessages.RB_Success'
    )
  };

  if (abroadBrokers) {
    return abroadEnglishVersion;
  }

  return localSwedishVersion;
};
