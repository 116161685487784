/** @format */
import config from 'config';
import React, { useCallback } from 'react';
import Image from 'react-bootstrap/Image';
import SimpleImageSlider from 'react-simple-image-slider';
import ObjectListingHeader from '../ObjectListingHeader';
import MapComponent from 'components/GoogleMapComp';
import { useTranslation } from 'react-i18next';
import Accordion from 'react-bootstrap/Accordion';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faUser } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import moment from 'moment';
import 'moment/locale/sv';
import getPropertyTypeTranslation from '../../../../utils/getPropertyTypeTranslation';

import { isInShowBiddingMode } from 'utils/biddingHelper';
import { BiddingBanner } from 'components/PropertyIndicators';
import { getFacts } from './facts';

export default function ObjectListingContents(props) {
  const { t } = useTranslation();
  const {
    coordinates,
    openBookAViewing,
    openValuationOfMyHome,
    openBidding,
    brokerDetails,
    description,
    estate,
    object
  } = props;

  const handleClickScroll = useCallback((id) => {
    const objectMap = {
      Bilder: 'images',
      Fakta: 'facts',
      Planritning: 'floorPlan',
      Karta: 'map'
    };
    const elements = document.querySelectorAll('#' + objectMap[id]);

    const header = document.querySelector('.header');

    const mobileStickyHeader = document.querySelector(
      '.imagelist-view-mobile .sticky-box'
    );

    const mobileStickyHeaderHeight =
      window.innerWidth < 920
        ? mobileStickyHeader.getBoundingClientRect().height
        : 0;

    elements.forEach((element) => {
      if (element) {
        const elementProportions = element.getBoundingClientRect();
        const topPosition = elementProportions.top;

        if (elementProportions.width !== 0 && elementProportions.height !== 0) {
          window.scrollTo({
            top:
              topPosition +
              window.scrollY -
              mobileStickyHeaderHeight -
              header.getBoundingClientRect().height,
            behavior: 'smooth'
          });
        }
      }
    });
  }, []);

  const modalButtonsText = t(
    `lang_common:object_listing.listings.modalButtons`
  );
  const objectText = t(`lang_common:object_listing`);

  const viewingArray = estate.viewings ? estate.viewings : [];

  let documentsArray = estate.documents ? estate.documents : [];
  documentsArray = documentsArray?.map((document) => {
    return {
      title: document.name,
      value: document.url
    };
  });

  const getAssessmentsTotal = (assessments) => {
    let total = 0;

    assessments?.forEach((assessment) => {
      assessment?.assessValues?.forEach((assessValue) => {
        total += assessValue.value;
      });
    });

    return total;
  };

  const getBooleanValues = (value) => {
    if (
      value === true ||
      value === 'true' ||
      value === 'yes' ||
      value === 'Yes'
    ) {
      return 'JA';
    } else if (
      value === false ||
      value === 'false' ||
      value === 'no' ||
      value === 'No'
    ) {
      return 'NEJ';
    } else {
      return value;
    }
  };

  const getNone = (value) => {
    if (value === 'None') {
      return 'Ej vald';
    }
    return value;
  };

  const getEnergyCompletedStatus = (value) => {
    return objectText?.energyCompleted[value];
  };

  const descriptionObj = [
    {
      title: objectText?.descriptionObj?.sellPhrase.text,
      value: estate?.description?.sellPhrase,
      label: false
    },
    {
      title: objectText?.descriptionObj?.sellingHeading.text,
      value: estate?.description?.sellingHeading,
      label: false
    },
    {
      title: objectText?.descriptionObj?.shortSellingDescription.text,
      value: estate?.description?.shortSellingDescription,
      label: false
    },
    {
      title: objectText?.descriptionObj?.longSellingDecription.text,
      value: estate?.description?.longSellingDescription,
      label: false
    },
    {
      title: objectText?.descriptionObj?.wayDescription.text,
      value: estate?.description?.directions,
      label: true
    },
    {
      title: objectText?.descriptionObj?.generalDescription.text,
      value: estate?.description?.generalDescription,
      label: true
    },
    {
      title: objectText?.descriptionObj?.other.text,
      value: estate?.description?.otherDescription,
      label: true
    }
  ];

  const basicInfoObj = [
    {
      title: objectText?.basicInfoObj?.propertyType.text,
      value: getPropertyTypeTranslation(
        estate?.estateType,
        objectText.propertyType
      )
    },
    {
      title: objectText?.basicInfoObj?.tenure.text,
      value: estate?.baseInformation?.tenure
    },
    {
      title: objectText?.basicInfoObj?.newBuild.text,
      value: getBooleanValues(estate?.baseInformation?.newConstruction)
    },
    {
      title: objectText?.basicInfoObj?.street.text,
      value: estate?.baseInformation?.address?.streetAddress
    },
    {
      title: objectText?.basicInfoObj?.zipCode.text,
      value: estate?.baseInformation?.address?.zipCode
    },
    {
      title: objectText?.basicInfoObj?.municipality.text,
      value: estate?.baseInformation?.address?.municipality
    },
    {
      title: objectText?.basicInfoObj?.city.text,
      value: estate?.baseInformation?.address?.city
    },
    {
      title: objectText?.basicInfoObj?.area.text,
      value: estate?.baseInformation?.address?.area
    },
    {
      title: objectText?.basicInfoObj?.propertyDesignation.text,
      value: estate?.baseInformation?.propertyUnitDesignation
    },
    {
      title: objectText?.basicInfoObj?.keyNumbers.text,
      value: estate?.baseInformation?.keyNumber
    },
    {
      title: objectText?.basicInfoObj?.preInspected.text,
      value: getBooleanValues(estate?.baseInformation?.preliminaryAssessedValue)
    },
    {
      title: objectText?.basicInfoObj?.sellersInsurance.text,
      value: getBooleanValues(estate?.baseInformation?.sellerInsurance)
    },
    {
      title: objectText?.basicInfoObj?.apartmentNumber.text,
      value: estate?.baseInformation?.apartmentNumber
    },
    {
      title: objectText?.basicInfoObj?.apartmentNumberRegistration.text,
      value: estate?.baseInformation?.apartmentNumberRegistration
    },
    {
      title: objectText?.basicInfoObj?.other.text,
      value: estate?.baseInformation?.otherInformation
    }
  ];

  const electricityConsumption =
    estate?.electricity?.electricityPowerConsumptionKWH;

  const livingSpace = estate?.baseInformation?.livingSpace;

  const patioSquareMeter =
    estate?.groundIncludetToHousingCooperative?.squereMetre;

  const otherSpace = estate?.baseInformation?.otherSpace;

  const plotArea = estate?.plot?.area;

  const energyConsumption = estate?.energyDeclaration?.energyConsumption;

  const primaryEnergyNumber = estate?.electricity?.primaryEnergyNumber;

  let { factsArry, factsArryExtra, factsArryApartment } = getFacts({
    objectText,
    estate,
    documentsArray,
    electricityConsumption,
    energyConsumption,
    getAssessmentsTotal,
    getBooleanValues,
    getEnergyCompletedStatus,
    getNone,
    livingSpace,
    otherSpace,
    patioSquareMeter,
    plotArea,
    primaryEnergyNumber
  });

  if (object.type === 'Condominium') {
    factsArry = [...factsArry, ...factsArryExtra];
  }

  if (object.type === 'HousingCooperative') {
    factsArry = [...factsArry, ...factsArryApartment];
  }

  const buttonTexts = objectText.listings?.buttons;

  const handleOpenBookAViewing = () => {
    openBookAViewing(true);
  };

  const handleOpenValuationOfMyHome = () => {
    openValuationOfMyHome(true);
  };

  const handleOpenBidding = () => {
    openBidding(true);
  };

  const getAssessmentRow = (label, value) => {
    return (
      value !== null &&
      value !== 0 &&
      value !== '' && (
        <div className='object-listing-accordian-list'>
          <div className='object-listing-accordian-list-left'>{label}</div>
          <div className='object-listing-accordian-list-right'>{value}</div>
        </div>
      )
    );
  };
  const getAssessValueListItem = (label, value) => {
    return (
      value !== null &&
      value !== 0 &&
      value !== '' && (
        <li>
          <strong>{label}-</strong> {value}
        </li>
      )
    );
  };

  const getAssessmentTotal = (assessment) => {
    let total = 0;
    assessment?.assessValues?.forEach((assessValue) => {
      total += assessValue.value;
    });
    return total;
  };

  const getAssessmentTable = (assessments) => {
    return assessments?.map((assessment) => (
      <div className='assessment' key={assessment.typeCode}>
        {getAssessmentRow(
          objectText?.assess?.typeCode?.label,
          assessment.typeCode
        )}
        {getAssessmentRow(
          objectText?.assess?.comment?.label,
          assessment.comment
        )}
        {getAssessmentRow(
          objectText?.assess?.taxAssessmentYear?.label,
          assessment.taxYear
        )}{' '}
        {getAssessmentRow(
          objectText?.assess?.assessmentTotal?.label,
          getAssessmentTotal(assessment)
        )}
        {getAssessmentRow(objectText?.assess?.taxFee?.label, assessment.taxFee)}
        {assessment.assessValues.length && (
          <div className='object-listing-accordian-list'>
            <div className='object-listing-accordian-list-left'>
              {objectText?.assess?.assessValues?.label}
            </div>
            <div className='object-listing-accordian-list-right'></div>
            <div>
              {assessment?.assessValues?.map((assessValue) => {
                return (
                  <ul key={assessValue.value + assessValue.valueYear}>
                    {getAssessValueListItem(
                      objectText?.assess?.evaluationUnit?.label,
                      assessValue.evaluationUnit
                    )}
                    {getAssessValueListItem(
                      objectText?.assess?.value?.label,
                      assessValue.value
                    )}
                    {getAssessValueListItem(
                      objectText?.assess?.valueYear?.label,
                      assessValue.valueYear
                    )}
                  </ul>
                );
              })}
            </div>
          </div>
        )}
      </div>
    ));
  };

  const getFactsValues = (facts) => {
    if (facts.title === objectText?.interiorObj?.listOfRooms?.label) {
      return (
        <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
          <div className='object-listing-accordian-list-left'>
            {facts?.title}
          </div>
          <div className='object-listing-accordian-list-right'></div>
          <div className='list-of-rooms-wrapper'>
            {facts?.value?.map((room) => (
              <div className='list-of-rooms' key={room.heading + room.text}>
                <strong>{room.heading}</strong> - {room.text}
              </div>
            ))}
          </div>
        </div>
      );
    } else {
      if (facts.title === objectText?.assessments.label) {
        if (facts?.value?.length) {
          return (
            <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
              <div className='object-listing-accordian-list-left'>
                {facts.title}
              </div>
              <div className='object-listing-accordian-list-right'></div>
              <div className='assessments'>
                {getAssessmentTable(facts?.value)}
              </div>
            </div>
          );
        } else {
          return <></>;
        }
      } else if (
        facts.title ===
          objectText?.participationAndRepairFund?.participationOffAnnualFee
            ?.label ||
        facts.title ===
          objectText?.participationAndRepairFund?.participationInAssociation
            ?.label
      ) {
        return (
          facts.value && (
            <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
              <div className='object-listing-accordian-list-left'>
                {facts.title}
              </div>
              <div className='object-listing-accordian-list-right'>
                {facts.value}%
              </div>
            </div>
          )
        );
      } else {
        return (
          facts.value && (
            <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
              <div className='object-listing-accordian-list-left'>
                {facts.title}
              </div>
              <div className='object-listing-accordian-list-right'>
                {facts.value}
              </div>
            </div>
          )
        );
      }
    }
  };

  const getDocuments = (document) => {
    return (
      <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
        <div className='object-listing-accordian-list-left'>
          {document.title}
        </div>
        <div className='object-listing-accordian-list-right'>
          <a
            className='document-download'
            href={document.value}
            target='_blank'
            rel='noreferrer'>
            {objectText?.document?.download?.label}
          </a>
        </div>
      </div>
    );
  };

  const getBrokerPagePath = () => {
    return `/profil?profileId=${brokerDetails.brokerId}`;
  };

  const BiddingInProgressIndicator = (props) => {
    if (isInShowBiddingMode(estate.settings?.bidSetting)) {
      return <BiddingBanner animation={true} {...props} />;
    }
    return <></>;
  };

  const ObjectListing = () => {
    return (
      <div className='object-listing-image-container'>
        <BiddingInProgressIndicator
          wrapperClassName='object-listing-image-bidding-indicator-wrapper'
          bannerClassName='btn-plain'
          labelText='Budgivning pågår'
        />

        <div className='object-listing-primary-image-container'>
          <Image
            rounded
            thumbnail={false}
            src={
              estate &&
              estate?.imageList.length > 0 &&
              estate.imageList?.[0].url
            }
            className='image-primary-image'
          />
        </div>
      </div>
    );
  };

  const DataSection = ({
    key = 0,
    alwaysOpen,
    headerText,
    additionalHeaderComponents = [],
    body = null,
    labelValueSet = []
  }) => {
    const BodySection = () => {
      if (body) {
        return body;
      }

      return <></>;
    };

    const LabelValueSection = () => {
      if (labelValueSet.length > 0) {
        let response = [];

        labelValueSet.forEach(({ label, value }) => {
          if (value) {
            response.push(
              <>
                <div className='object-listing-accordian-list object-listing-accordian-list-no-border'>
                  {label && (
                    <div className='object-listing-accordian-list-left'>
                      {label}
                    </div>
                  )}
                  <div
                    className={`object-listing-accordian-list-${
                      label ? 'right' : 'full'
                    }`}>
                    {value}
                  </div>
                </div>
              </>
            );
          }
        });

        return response;
      }

      return <></>;
    };

    return (
      <Accordion alwaysOpen={alwaysOpen}>
        <Accordion.Item eventKey={0} key={key}>
          <Accordion.Header className='object-listing-accordion-header'>
            <span className='object-listing-accordion-header-title'>
              {headerText}
            </span>
            {additionalHeaderComponents.length > 0 &&
              additionalHeaderComponents.map((element) => element)}
          </Accordion.Header>
          <Accordion.Body>
            <BodySection />
            <LabelValueSection />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const BrokerInfo = () => (
    <div className='object-listing-contact-card'>
      <div className='object-listing-contact-card-content'>
        <div className='object-listing-contact-card-content-image-container'>
          <a href={getBrokerPagePath()}>
            <img
              src={brokerDetails.profileImageUrl}
              className='object-listing-contact-card-image object-listing-contact-card-image-clickable'
              alt='agent'
            />
          </a>
        </div>
        <div className='object-listing-contact-card-content-container'>
          <div className='object-listing-contact-card-content-details object-listing-contact-card-content-details-left'>
            <div className='object-listing-contact-card-content-name'>
              {brokerDetails.firstName + ' ' + brokerDetails.lastName}
            </div>
            <div className='object-listing-contact-card-content-job'>
              {objectText.contactCard.label.text}
            </div>
            <div className='object-listing-contact-card-content-buttons'>
              <Row>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <a href={`tel:${brokerDetails.phoneNumber}`}>
                    <Button
                      className={` btn-ashe object-listing-contact-card-content-sq-button`}>
                      <FontAwesomeIcon icon={faPhone} size='lg' />
                      {brokerDetails.phoneNumber}
                      {/* 0766503820 */}
                    </Button>
                  </a>
                </Col>
                <Col xs={12} sm={12} md={6} lg={6}>
                  <a href={getBrokerPagePath()}>
                    <Button
                      className={`btn btn-ashe object-listing-contact-card-content-sq-button`}>
                      <FontAwesomeIcon icon={faUser} size='lg' />
                      {modalButtonsText.toBroker?.label}
                    </Button>
                  </a>
                </Col>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <a href={`mailto:${brokerDetails.email}`}>
                    <Button
                      className={`btn btn-ashe object-listing-contact-card-content-sq-button`}>
                      <FontAwesomeIcon icon={faEnvelope} size='lg' />
                      {brokerDetails.email}
                    </Button>
                  </a>
                </Col>
              </Row>
            </div>
          </div>
          <div className='object-listing-contact-card-content-details object-listing-contact-card-content-details-right'>
            <Button
              className={`btn  btn-pink object-listing-contact-card-content-button`}
              onClick={handleOpenBookAViewing}>
              {modalButtonsText.bookAViewing?.label}
            </Button>
            <Button
              className={`btn btn-pink object-listing-contact-card-content-button`}
              onClick={handleOpenValuationOfMyHome}>
              {modalButtonsText.valuationOfMyHome?.label}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className='object-listing-image-slider-mobile'>
        <SimpleImageSlider
          width={350}
          height={250}
          images={
            estate &&
            estate?.imageList.length > 0 &&
            estate?.imageList?.map((item) => item.url)
          }
          showBullets={false}
          showNavs={true}
        />
      </div>
      {/* <BiddingInProgressIndicator
        wrapperClassName='object-listing-image-bidding-indicator-wrapper mobile'
        bannerClassName='btn-pink'
        labelText='Budgivning pågår'
      /> */}
      <ObjectListing estate={estate} {...props} />
      <ObjectListingHeader
        buttonText={buttonTexts}
        startingPrice={estate?.price?.priceText}
        priceTextLabel={objectText?.starting_price?.label}
        biddingStarted={objectText?.bidding_started?.label}
        finalPrice={objectText?.final_price?.label}
        priceText={estate?.price?.startingPrice}
        finalPriceText={estate?.price?.finalPrice}
        isBidding={estate?.bids && estate?.bids?.length > 0}
        isFinalPrice={
          estate?.price?.finalPrice && Number(estate?.price?.finalPrice) > 0
        }
        {...props}
        handleClick={handleClickScroll}
        countryCode={object?.countryCode}
        country={object?.country}
      />

      <div className='object-listing-sales-headline'>
        <h5 className='object-listing-headling-title'>
          {estate?.description?.sellingHeading}
        </h5>
        <p className='object-listing-headling-description'>
          {estate?.description?.sellPhrase}
        </p>

        <Row>
          <Col md={6}>
            <DataSection
              alwaysOpen={estate.bids.length > 0}
              headerText={objectText?.bidding.label}
              additionalHeaderComponents={[
                // eslint-disable-next-line react/jsx-key
                <BiddingInProgressIndicator
                  wrapperClassName='object-bidding in-progress-flag'
                  bannerClassName='btn-plain'
                  labelText='Budgivning pågår'
                />
              ]}
              body={
                <Button
                  className={`btn btn-white object-listing-viewings-button`}
                  onClick={() => handleOpenBidding()}>
                  {modalButtonsText.viewBiddings?.label}
                </Button>
              }
            />
          </Col>
          <Col md={6}>
            <Accordion
              defaultActiveKey={0}
              alwaysOpen={viewingArray.length > 0}>
              <Accordion.Item eventKey={0} key={0}>
                <Accordion.Header className='object-listing-accordion-header'>
                  <span className='object-listing-accordion-header-title'>
                    {objectText?.viewings.label}
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  <div className='object-listing-accordian-viewings'>
                    {viewingArray &&
                      viewingArray.length > 0 &&
                      viewingArray?.map((item) => {
                        return (
                          <div
                            className='object-listing-accordian-list'
                            key={item.id}>
                            <div className='object-listing-accordian-list-left object-listing-accordian-list-left-viewings'>
                              {`${moment(item.startTime).format(
                                'dddd D/M'
                              )} kl ${moment(item.startTime).format('HH:mm')}
                                - ${moment(item.endTime).format('HH:mm')}`}
                            </div>
                            <div className='object-listing-accordian-list-left object-listing-accordian-list-left-viewings'>
                              {item.commentary}
                            </div>
                            {/* Removed as a request from client on 2023/02/22 */}
                            {/* <div className='object-listing-accordian-list-left object-listing-accordian-list-left-participants'>
                              {`${item.participants.length} ${
                                item.participants.length !== 1
                                  ? 'participants'
                                  : 'participant'
                              }`}
                            </div> */}
                          </div>
                        );
                      })}

                    <Button
                      className={`btn btn-white object-listing-viewings-button`}
                      onClick={() => handleOpenBookAViewing()}>
                      {modalButtonsText.bookAViewing?.label}
                    </Button>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Col>
        </Row>
      </div>
      <div className='object-listing-description-headling'>
        <h5 className='object-listing-headling-title'>
          {objectText.description.label}
        </h5>
        <p className='object-listing-headling-description'>{description}</p>

        <Row>
          <Col md={6}>
            <DataSection
              alwaysOpen={false}
              headerText={objectText?.basicInformation.label}
              labelValueSet={basicInfoObj?.map(({ label, value }) => ({
                label,
                value
              }))}
            />
          </Col>
          <Col md={6}>
            <DataSection
              alwaysOpen={false}
              headerText={objectText?.description.label}
              labelValueSet={descriptionObj?.map(({ label, value }) => ({
                label,
                value
              }))}
            />
          </Col>
        </Row>
      </div>

      <BrokerInfo />

      <div className='object-listing-facts' id='facts'>
        <h5 className='object-listing-headling-title'>
          {objectText.facts.label}
        </h5>

        <Row>
          {factsArry?.map((item) => {
            if (
              estate?.estateType !== 'HousingCooperative' ||
              (item.title !== objectText?.enrolmentPlans?.label &&
                item.title !== objectText?.waterasw?.label &&
                item.title !== objectText?.assess?.label)
            )
              return (
                <Col md={6} key={item.title}>
                  <DataSection
                    key={item.title + item}
                    alwaysOpen={false}
                    headerText={item.title}
                    body={
                      <>
                        {item.title === objectText?.documents?.label
                          ? item?.body?.map(
                              (i) =>
                                i.value !== null &&
                                i.value !== 0 &&
                                i.value !== '0' &&
                                i.value !== '' && (
                                  <React.Fragment key={i.title + i.value}>
                                    {getDocuments(i)}
                                  </React.Fragment>
                                )
                            )
                          : item &&
                            item?.body.length > 0 &&
                            item?.body?.map(
                              (i) =>
                                i.value !== null &&
                                i.value !== 0 &&
                                i.value !== '0' &&
                                i.value !== '' && (
                                  <React.Fragment key={i.title + i.value}>
                                    {getFactsValues(i)}
                                  </React.Fragment>
                                )
                            )}
                      </>
                    }
                  />
                </Col>
              );
          })}
        </Row>
      </div>
      <div className='object-listing-facts' id='images'>
        <h5 className='object-listing-headling-title'>
          {objectText?.objectImages.label}
        </h5>
        <Row gap={16}>
          {estate.imageList
            .filter((item) => item.category !== 'Planritningar')
            .map((item) => (
              <Col md={6} key={item?.url}>
                <Image
                  rounded
                  thumbnail={true}
                  src={item?.url}
                  className='object-listing-image'
                  id={item?.url}
                />
                {item.text && <div className='image-overlay'>{item?.text}</div>}
              </Col>
            ))}
        </Row>
      </div>
      <div className='object-listing-facts' id='floorPlan'>
        <h5 className='object-listing-headling-title'>
          {objectText?.floorPlan.label}
        </h5>
        <Row>
          {estate.imageList
            .filter((item) => item.category === 'Planritningar')
            .map((item) => (
              <Col md={6} key={item?.url}>
                <Image
                  rounded
                  thumbnail={true}
                  src={item?.url}
                  className='object-listing-image'
                  id={item?.url}
                />
                {item.text && <div className='image-overlay'>{item?.text}</div>}
              </Col>
            ))}
        </Row>
      </div>
      <div className='object-listing-map' id='map'>
        <h5 className='object-listing-headling-title'>
          {objectText?.map.label}
        </h5>
        <div style={{ height: '400px', width: '100%' }}>
          <MapComponent
            latitude={parseFloat(coordinates.latitud)}
            longitude={parseFloat(coordinates.longitud)}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${config.app.googleMapsApiKey}&v=weekly`}
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      </div>
    </>
  );
}
