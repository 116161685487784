/** @format */

import React, { lazy, Suspense } from 'react';

const flags = {
  ES: lazy(() => import('country-flag-icons/react/3x2/ES')),
  US: lazy(() => import('country-flag-icons/react/3x2/US')),
  FR: lazy(() => import('country-flag-icons/react/3x2/FR')),
  IT: lazy(() => import('country-flag-icons/react/3x2/IT')),
  UK: lazy(() => import('country-flag-icons/react/3x2/GB')),
  GB: lazy(() => import('country-flag-icons/react/3x2/GB')),
  DE: lazy(() => import('country-flag-icons/react/3x2/DE')),
  AU: lazy(() => import('country-flag-icons/react/3x2/AU')),
  BR: lazy(() => import('country-flag-icons/react/3x2/BR')),
  FI: lazy(() => import('country-flag-icons/react/3x2/FI')),
  GR: lazy(() => import('country-flag-icons/react/3x2/GR')),
  NO: lazy(() => import('country-flag-icons/react/3x2/NO')),
  PL: lazy(() => import('country-flag-icons/react/3x2/PL')),
  NZ: lazy(() => import('country-flag-icons/react/3x2/NZ')),
  RU: lazy(() => import('country-flag-icons/react/3x2/RU')),
  DK: lazy(() => import('country-flag-icons/react/3x2/DK')),
  SE: lazy(() => import('country-flag-icons/react/3x2/SE'))
};

const Flag = ({ countryCode }) => {
  if (countryCode) {
    const CountryFlag = flags[countryCode];

    return (
      <div className='flag'>
        <Suspense fallback={<></>}>
          <CountryFlag />
        </Suspense>
      </div>
    );
  }

  return <></>;
};

export default Flag;
